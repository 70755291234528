import React from "react";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Link from "next/link";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import PublicIcon from "@mui/icons-material/Public";
import Button from "@mui/material/Button";

const Footer = () => {
  const footerLinks = [
    { id: 1, text: "Privacy", url: "#" },
    { id: 1, text: "Terms", url: "#" },
    { id: 1, text: "Sitemap", url: "#" },
    { id: 1, text: "Destinations", url: "#" },
  ];

  return (
    <Box
      mt={10}
      sx={{
        display: "flex",
        width: "100%",
        borderTop: "1px solid #ddd",
      }}
    >
      <Container maxWidth="xl">
        <Box
          sx={{
            display: "flex",
            justifyContent: {
              xs: "center",
              md: "space-between",
              alignItems: "center",
              width: "100%",
            },
          }}
        >
          <Stack>
            <Paper>
              <Link href="#">2022 Website copyright</Link>
            </Paper>
            {footerLinks.map((link) => {
              return (
                <Paper key={link.id}>
                  <Link href={link.url}>{link.text}</Link>
                </Paper>
              );
            })}
          </Stack>

          <Stack>
            <Paper sx={{ display: "flex", justifyContent: "center" }}>
              <Button>
                <Box sx={{ display: "flex", justifyContent: "center", mr: 1 }}>
                  <PublicIcon />
                </Box>
                English (CA)
              </Button>
              <Button>$CAD</Button>
              <Button>
                {" "}
                Support and Resources
                <Box sx={{ display: "flex", justifyContent: "center", ml: 1 }}>
                  <ExpandLessIcon />
                </Box>
              </Button>
            </Paper>
          </Stack>
        </Box>
      </Container>
    </Box>
  );
};

export default Footer;
